import styled from 'styled-components';

export const ImageWrapper = styled.figure`
    margin-bottom: 40px;
`;

export const Wrapper = styled.article`
    margin: 0 auto;
    flex-direction: column;
    max-width: 1280px;

    > h1 {
        text-align: center;
    }
`;
