import { css } from 'styled-components';

import { Coords, Position } from '@/types';
import { isDefined } from '@/utils';

/**
 * Mixin um einfach eine absolute / fixed Position anzugeben
 * @param coords Objekt der möglichen "Eckpositionen"
 * @param pos
 * @returns CSS um die position zu setzen
 * @example
 *      const Text = styled.p`
 *          ${position({ top: '50%', left: '50%' })}
 *      `;
 *
 *      Ergebnis:
 *      position: absolute;
 *      top: 50%;
 *      left: 50%;
 */
export const position = (coords: Coords, pos?: Position) => css`
    position: ${pos ?? `absolute`};
    ${coords && isDefined(coords.top) ? `top: ${coords.top};` : null}
    ${coords && isDefined(coords.right) ? `right: ${coords.right};` : null}
    ${coords && isDefined(coords.bottom) ? `bottom: ${coords.bottom};` : null}
    ${coords && isDefined(coords.left) ? `left: ${coords.left};` : null}
`;
