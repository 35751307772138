import { ReactNode } from 'react';

import { Gap, ImagePosition, ImageWidth } from '@/types';
import { useSlider } from '@/hooks';

import { ArrowButton } from '../../common/ArrowButton';

import { ImagesWrapper, ImageWrapper, UIWrapper, Wrapper } from './styled';

interface TextImageBlockProps {
    /** Die Bildbreite */
    imageWidth?: ImageWidth;
    /** Die Position des Bildes */
    imagePosition?: ImagePosition;
    /** Abstand nach unten */
    gap?: Gap;
    /** Flag, ob der Text das Bild umfließen soll */
    isTextFloating?: Boolean;
    /** Kindelemente, hier der Text */
    children: ReactNode;
    /** Bilder, die in den Slider sollen */
    images: Array<{
        image: ReactNode;
        caption?: string;
    }>;
    /** Maximale Breite des ganzen Blocks (default 900px) */
    maxWidth?: string;
}

/**
 * Stellt einen Text/Bild Block zur Verfügung.
 * Wird mehr als ein Bild zur Verfügung gestellt, wird automatisch ein Slider verwendet.
 * Text kann entweder das Bild umfließen, oder spaltig dargestellt werden
 */
export const TextImageBlock = ({
    children,
    gap,
    imagePosition = `right`,
    imageWidth = `50%`,
    images,
    isTextFloating,
    maxWidth
}: TextImageBlockProps) => {
    const { activeItemIndex: activeImageIndex, onPrevOrNextButtonClick, swipeHandlers } = useSlider({ items: images });
    const { image, caption } = images[activeImageIndex];

    return (
        <Wrapper $imagePosition={imagePosition} $isTextFloating={isTextFloating} $gap={gap} $maxWidth={maxWidth}>
            <ImagesWrapper $imageWidth={imageWidth} {...swipeHandlers}>
                <ImageWrapper>
                    <div>
                        {images.length > 1 && (
                            <UIWrapper>
                                <ArrowButton data-direction="prev" title="Vorheriges Bild" onClick={onPrevOrNextButtonClick} />
                                <ArrowButton
                                    direction="right"
                                    title="Nächstes Bild"
                                    data-direction="next"
                                    onClick={onPrevOrNextButtonClick}
                                />
                            </UIWrapper>
                        )}
                        {image}
                    </div>
                    {caption && (
                        <figcaption>
                            <small>{caption}</small>
                        </figcaption>
                    )}
                </ImageWrapper>
            </ImagesWrapper>
            {children}
        </Wrapper>
    );
};
