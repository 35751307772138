import { ReactNode } from 'react';

import { Gap, HeadlineLevel } from '@/types';

import { Copy } from '../Intro/styled';

import { Author, Headline, ImageWrapper, ImageWrapperInner, TextWrapper, TextWrapperInner, Wrapper } from './styled';

interface QuoteBoxProps {
    headline: {
        text: ReactNode;
        as?: HeadlineLevel;
    };
    copy: string;
    author?: string;
    image: ReactNode;
    isStyledAsBox?: boolean;
    gap?: Gap;
}

/**
 * Box um ein Zitat darzustellen
 */
export const QuoteBox = ({ headline, copy, author, image, isStyledAsBox, gap }: QuoteBoxProps) => (
    <Wrapper className={`quotebox ${isStyledAsBox && `quotebox--boxstyled`}`} $isStyledAsBox={isStyledAsBox} $gap={gap}>
        {isStyledAsBox && author && (
            <Author asHeadline withoutLine>
                {author}
            </Author>
        )}
        <TextWrapper>
            <TextWrapperInner>
                <Headline as={headline.as ?? `h2`}>{headline.text}</Headline>
                <Copy>{copy}</Copy>
                {!isStyledAsBox && author && <Author>{author}</Author>}
            </TextWrapperInner>
        </TextWrapper>
        <ImageWrapper>
            <ImageWrapperInner>{image}</ImageWrapperInner>
        </ImageWrapper>
    </Wrapper>
);
