import { Contact, Copyright, LegalLink, LegalLinks, Wrapper } from './styled';

/**
 * Footer Komponente
 */
export const Footer = () => (
    <Wrapper id="kontakt">
        <Copyright>&copy; Mantrailer Neckar-Alb e.V.</Copyright>
        <Contact>
            <a href="tel:+4901727546356">+49 (0)172 / 7546356</a>
            <div>|</div>
            <a href="mailto:info@mantrailer-neckar-alb.de">info@mantrailer-neckar-alb.de</a>
        </Contact>
        <LegalLinks>
            <LegalLink to="/impressum">Impressum</LegalLink>
            <div>|</div>
            <LegalLink to="/datenschutz">Datenschutz</LegalLink>
        </LegalLinks>
    </Wrapper>
);
