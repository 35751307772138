import styled, { css } from 'styled-components';

import { breakpoint, gapable, remCalc, vwMinMax, color } from '@/style';
import { Gap } from '@/types';

/**
 * Wrapper des Intros
 */
export const Wrapper = styled.div<{ $textAlignLeft?: boolean | { breakpoint: number }; $gap?: Gap }>`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    ${vwMinMax({ cssProp: `maxWidth`, minValue: 600, maxValue: 1200, minViewport: breakpoint.medium })};
    ${({ $textAlignLeft }) =>
        $textAlignLeft && typeof $textAlignLeft === `boolean`
            ? `
                text-align: left;
            `
            : `
                text-align: center;
                margin-left: auto;
                margin-right: auto;
    `};

    ${({ $textAlignLeft }) =>
        $textAlignLeft &&
        typeof $textAlignLeft === `object` &&
        $textAlignLeft.breakpoint &&
        css`
            @media (min-width: ${$textAlignLeft.breakpoint}px) {
                text-align: left;
                margin-left: 0;
                margin-right: 0;
            }
        `};

    ${gapable()};
`;

/**
 * Headline des Intros
 */
export const Headline = styled.span<{ $isSmall?: boolean; $isDecolineHidden?: boolean }>`
    margin-bottom: 0;
    ${({ $isSmall }) =>
        $isSmall
            ? css`
                  ${vwMinMax({ cssProp: `fontSize`, minValue: 16, maxValue: 25 })};
              `
            : css`
                  ${vwMinMax({ cssProp: `fontSize`, minValue: 25, maxValue: 40 })};
              `};
    ${({ $isDecolineHidden }) =>
        !$isDecolineHidden
            ? css`
                  &::after {
                      ${vwMinMax({ cssProp: `width`, minValue: 60, maxValue: 120 })};
                      background-color: ${color.primary};
                      content: '';
                      display: block;
                      height: ${remCalc(3)};
                      margin: 0.5em auto 0.65em auto;
                  }
              `
            : `margin-bottom: 0.15em;`};
`;

/**
 * Copytext des Intros
 */
export const Copy = styled.p<{ $small?: boolean; $marginBottom?: boolean }>`
    ${vwMinMax({ cssProp: `fontSize`, minValue: 16, maxValue: 23 })};
    margin-bottom: ${({ $marginBottom }) => ($marginBottom ? `0.8em` : `0`)};
    ${({ $small }) =>
        $small
            ? vwMinMax({ cssProp: `fontSize`, minValue: 14, maxValue: 20 })
            : vwMinMax({ cssProp: `fontSize`, minValue: 16, maxValue: 23 })};
`;
