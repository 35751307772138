import { GatsbyImage } from 'gatsby-plugin-image';

import { TeamData } from '@/types';

import { isDefined } from '../isDefined';

/**
 * Transfomiert Daten der Team-Query (allPrismicTeam) in ein renderbares Format
 */
export const transformTeamsDetailQueryData = ({ detailImage, dog, members }: TeamData['data']) => ({
    image: detailImage ? <GatsbyImage image={detailImage.gatsbyImageData} alt={detailImage.alt ?? ``} /> : null,
    title: !isDefined(dog.document.data.death_date)
        ? `${members.map(({ member }) => member.document.data.firstname).join(`, `)} & ${dog.document.data.name}`
        : (dog.document.data.name as string),
    details: {
        breed: dog.document.data.breed as string,
        birthday: dog.document.data.birthday as string,
        dogDeathDate: dog.document.data.death_date as string,
        mantrailerSince: dog.document.data.mantrailer_since as string,
        dogHandler: members.map(({ member }) => member.document.data.firstname).join(`, `),
        dogName: dog.document.data.name as string
    }
});
