import { ReactNode } from 'react';
import CookieConsent from 'react-cookie-consent';
import { ErrorBoundary } from 'react-error-boundary';

import { color, GlobalStyle } from '@/style';
import { Header, ScrollTop, Footer } from '@/components/scaffold';
import { Intro } from '@/components/common';

import { Main, ErrorPre } from './styled';

const Layout = ({ children }: { children: ReactNode }) => (
    <>
        <GlobalStyle />
        <Header id="top" />
        <ScrollTop />

        <Main>
            <ErrorBoundary
                fallbackRender={({ error }) => (
                    <Intro
                        copy={
                            <ErrorPre>
                                {error.message} <br /> {error.stack}
                            </ErrorPre>
                        }
                        headline={{
                            text: `Something went wrong`,
                            as: `h1`,
                            isDecolineHidden: true
                        }}
                    />
                )}
            >
                {children}
            </ErrorBoundary>
        </Main>
        <CookieConsent
            buttonText="Verstanden"
            buttonStyle={{ color: color.white, backgroundColor: `transparent`, border: `2px solid ${color.white}` }}
        >
            Diese Website verwendet nur technisch notwendige Cookies.
        </CookieConsent>
        <Footer />
    </>
);

export default Layout;
