import { ReactNode, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useIntersectionObserver } from 'usehooks-ts';

import { remCalc, vwMinMax } from '@/style';

interface SectionProps {
    id?: string;
    bgColor?: string;
    children: ReactNode;
    isAnimated?: boolean;
    className?: string;
}

const Wrapper = styled.section<{ $bgColor?: string; $isVisible?: boolean }>`
    flex-grow: 1;
    ${vwMinMax({ cssProp: `marginBottom`, minValue: 50, maxValue: 100 })};
    padding: ${remCalc(0, 15)};

    ${({ $bgColor }) =>
        $bgColor &&
        css`
            background-color: ${$bgColor};
            ${vwMinMax({ cssProp: `padding`, minValue: [40, 15], maxValue: [70, 15] })};

            &:only-of-type,
            &:last-of-type {
                ${vwMinMax({ cssProp: `padding`, minValue: [40, 15, 80], maxValue: [70, 15, 140] })};
                margin-bottom: 0;
            }
        `};

    transition: opacity 1s 0.15s;
    opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};

    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
        ${vwMinMax({ cssProp: `fontSize`, minValue: 25, maxValue: 40 })};
        margin-bottom: 0.85em;
        text-align: center;
    }
`;

/**
 * Section um Inhalte zu wrappen und ggf. zu animieren
 */
export const Section = ({ id, bgColor, children, isAnimated = true, className }: SectionProps) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(ref, { freezeOnceVisible: true, threshold: 0 });
    const isVisible = !isAnimated || !!entry?.isIntersecting;

    return (
        <Wrapper ref={isAnimated ? ref : null} id={id} $isVisible={isVisible} $bgColor={bgColor} className={className}>
            {children}
        </Wrapper>
    );
};
