import { remCalc } from './remCalc';
import { breakpoint } from './variables';

/**
 * Diverse, vordefinierte MediaQueries
 */
export const mq = {
    smallOnly: `(max-width: ${remCalc(breakpoint.medium - 1)})`,
    medium: `(min-width: ${remCalc(breakpoint.medium)})`,
    mediumDown: `(max-width: ${remCalc(breakpoint.medium - 1)})`,
    mediumOnly: `(min-width: ${remCalc(breakpoint.medium)}) and (max-width: ${remCalc(breakpoint.large - 1)})`,
    large: `(min-width: ${remCalc(breakpoint.large)})`,
    largeDown: `(max-width: ${remCalc(breakpoint.large - 1)})`,
    largeOnly: `(min-width: ${remCalc(breakpoint.large)}) and (max-width: ${remCalc(breakpoint.xlarge - 1)})`,
    xlarge: `(min-width: ${remCalc(breakpoint.xlarge)})`,
    xLargeDown: `(max-width: ${remCalc(breakpoint.xlarge - 1)})`,
    xlargeOnly: `(min-width: ${remCalc(breakpoint.xlarge)}) and (max-width: ${remCalc(breakpoint.xxlarge - 1)})`,
    xxlarge: `(min-width: ${remCalc(breakpoint.xxlarge)})`,
    xxLargeDown: `(max-width: ${remCalc(breakpoint.xxlarge - 1)})`,
    xxlargeOnly: `(min-width: ${remCalc(breakpoint.xxlarge)}) and (max-width: ${remCalc(breakpoint.xxxlarge - 1)})`,
    xxxlarge: `(min-width: ${remCalc(breakpoint.xxxlarge)})`
};

export type Mq = keyof typeof mq;
