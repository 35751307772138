import { css } from 'styled-components';

/**
 * Helper um Code in Pseudo-Klassen für Hover etc. zu wrappen
 */
export const pseudoStateClasses = (args: any) => css`
    &:hover,
    &:focus,
    &:active {
        ${css(...(args as [any, any]))};
    }
`;
