import { ReactNode } from 'react';

import { Icon, Link } from './styled';

interface OverviewLinkProps {
    to?: string;
    children?: ReactNode;
    title?: string;
}

const defaultTitle = `Zurück zur Übersicht`;

/**
 * Stellt einen - zentrierten - Link zur Verfügung, der dazu genutzt
 * werden kann, um zu Übersichten zurück zu gelangen
 */
export const OverviewLink = ({ to = `../`, children = defaultTitle, title = defaultTitle }: OverviewLinkProps) => (
    <Link to={to} title={title}>
        <Icon />
        <span>{children}</span>
    </Link>
);
