import { StaticImage } from 'gatsby-plugin-image';

const homeStageImageProps = {
    quality: 80,
    aspectRatio: 1280 / 760
};

export const homestageImages = [
    <StaticImage
        key="homestage-01"
        src="../assets/img/homestage/homestage-01.jpg"
        alt="Lotte und Sophia in Aktion"
        {...homeStageImageProps}
    />,
    <StaticImage key="homestage-02" src="../assets/img/homestage/homestage-02.jpg" alt="Gruppe von Mantrailern" {...homeStageImageProps} />,
    <StaticImage key="homestage-03" src="../assets/img/homestage/homestage-03.jpg" alt="Pablo beim Anriechen" {...homeStageImageProps} />,
    <StaticImage key="homestage-04" src="../assets/img/homestage/homestage-04.jpg" alt="Hope auf einem Trail" {...homeStageImageProps} />,
    <StaticImage
        key="homestage-05"
        src="../assets/img/homestage/homestage-05.jpg"
        alt="Benthe beim Start des Trails"
        {...homeStageImageProps}
    />,
    <StaticImage
        key="homestage-06"
        src="../assets/img/homestage/homestage-06.jpg"
        alt="Pablo und Jutta in Aktion"
        {...homeStageImageProps}
    />,
    <StaticImage
        key="Boxerhündin Hope trailt auf der Straße"
        src="../assets/img/homestage/homestage-07.jpg"
        alt="Bild 2"
        {...homeStageImageProps}
    />,
    <StaticImage key="homestage-08" src="../assets/img/homestage/homestage-08.jpg" alt="Gruppe von Mantrailern" {...homeStageImageProps} />,
    <StaticImage
        key="homestage-09"
        src="../assets/img/homestage/homestage-09.jpg"
        alt="Terrier-Mix Nele sucht im Fußballstadion"
        {...homeStageImageProps}
    />,
    <StaticImage
        key="homestage-10"
        src="../assets/img/homestage/homestage-10.jpg"
        alt="Labrador Pablo in Aktion"
        {...homeStageImageProps}
    />
];
