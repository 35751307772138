import { StaticImage } from 'gatsby-plugin-image';

import { ArticleTeaser } from '@/components/blog';

const imagePath = `../assets/img/blog`;

export const articleTeaser = [
    <ArticleTeaser
        key="erste-hilfe-outdoor-kurs"
        gap={[30, 50]}
        headline={{ text: `Erste Hilfe Outdoor Kurs` }}
        copy="Erste Hilfe Kurs mit dem Plattenhardter DRK und unseren Hunden."
        image={
            <StaticImage
                src={`${imagePath}/erste-hilfe-outdoor-kurs/wiederbelebung.jpg`}
                alt="Übung zur Wiederbelebung"
                width={480}
                height={320}
            />
        }
        to="/blog/erste-hilfe-outdoor-kurs"
        date="2023-05-07"
    />,
    <ArticleTeaser
        key="training-am-flughafen-stuttgart"
        gap={[30, 50]}
        headline={{ text: `Training am Flughafen Stuttgart` }}
        copy="Außergewöhnliches Training für Mensch und Hund in ungewohnter Umgebung."
        image={
            <StaticImage
                src={`${imagePath}/training-am-flughafen-stuttgart/bereit-zum-einsatz.jpg`}
                alt="Bereit zum Einsatz"
                width={480}
                height={320}
            />
        }
        to="/blog/training-am-flughafen-stuttgart"
        date="2023-03-07"
    />,
    <ArticleTeaser
        key="drei-koenigsspaziergang-2023"
        gap={[30, 50]}
        headline={{ text: `Drei-Königsspaziergang 2023` }}
        copy="Am 06.01.2023 trafen wir uns zu einem Drei-Königsspaziergang in Steinenbronn."
        image={
            <StaticImage
                src={`${imagePath}/drei-koenigsspaziergang-2023/einkehr-im-cafe.jpg`}
                alt="Einkehr im Cafe Haag"
                width={480}
                height={320}
            />
        }
        to="/blog/drei-koenigsspaziergang-2023"
        date="2023-01-10"
    />,
    <ArticleTeaser
        key="training-auf-dem-fussballplatz"
        gap={[30, 50]}
        headline={{ text: `Training auf dem Fussballplatz` }}
        copy="Am 06.11.2022 trafen wir uns um 15 Uhr zu einem besonderen Training. Wir wussten noch nicht, was uns erwartete, als wir uns in Plattenhardt auf dem Weilerhauparkplatz zusammenfanden."
        image={
            <StaticImage
                src={`${imagePath}/training-auf-dem-fussballplatz/pablo-sucht.jpg`}
                alt="Labrador Pablo auf der Spur"
                width={480}
                height={320}
            />
        }
        to="/blog/training-auf-dem-fussballplatz"
        date="2022-11-10"
    />,
    <ArticleTeaser
        key="einsatz-in-stuttgart-moehringen"
        gap={[30, 50]}
        headline={{ text: `Einsatz in Stuttgart-Möhringen` }}
        copy="Am Abend des 22.07.22 wurden wir von einer verzweifelten Dame um Hilfe gebeten, da ihr dementer Mann schon seit über einem Tag vermisst wurde."
        image={
            <StaticImage
                src={`${imagePath}/einsatz-in-stuttgart-moehringen/lotte-sucht.jpg`}
                alt="Deutsch-Drahthaar Hündin Lotte sucht"
                width={480}
                height={320}
            />
        }
        to="/blog/einsatz-in-stuttgart-moehringen"
        date="2022-08-01"
    />
];
