import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { breakpoint, color, gapable, hoverSpeed, remCalc, vwMinMax } from '@/style';
import { Gap } from '@/types';

import { ELEMENT_BREAKPOINT } from './constants';

/**
 * Wrapper
 */
export const Wrapper = styled.div<{ $gap?: Gap; $isFlexReversed?: boolean }>`
    ${vwMinMax({ cssProp: `marginBottom`, minValue: 20, maxValue: 30 })};
    display: block;
    background-color: ${color.white};
    margin-left: auto;
    margin-right: auto;
    color: ${color.black};
    max-width: ${remCalc(400)};
    position: relative;
    text-decoration: none;
    transition:
        transform ${hoverSpeed},
        box-shadow ${hoverSpeed};

    @media (min-width: ${ELEMENT_BREAKPOINT}px) {
        display: flex;
        flex-direction: ${({ $isFlexReversed }) => ($isFlexReversed ? `row-reverse` : `row`)};
        &:hover {
            transform: translateY(${remCalc(-8)});
            box-shadow: ${remCalc(0, 15, 30, 0)} ${rgba(color.black, 0.2)};
        }
    }

    &:hover {
        .more-link {
            text-decoration: underline;
        }
    }

    ${vwMinMax({
        cssProp: `maxWidth`,
        minValue: 650,
        maxValue: 1280,
        minViewport: ELEMENT_BREAKPOINT,
        maxViewport: breakpoint.xxxlarge,
        noMobileMQ: true
    })};

    ${gapable()};
`;

/**
 * Wrapper für den Datumsblock
 */
export const DateWrapper = styled.div`
    background-color: ${color.primary};
    text-align: center;
    padding: 0.65em 0.5em 0.5em;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;

    @media (min-width: ${ELEMENT_BREAKPOINT}px) {
        flex-direction: column;
        gap: 0;
        padding: 1em 0.75em;
    }
`;

/**
 * Generelle Styles für das Datum
 */
export const dateStyles = css`
    ${vwMinMax({ cssProp: `fontSize`, minValue: 18, maxValue: 20 })};
    display: block;
    color: ${color.white};
`;

/**
 * Monat
 */
export const DateMonth = styled.div`
    ${dateStyles};
    text-transform: uppercase;
    @media (min-width: ${ELEMENT_BREAKPOINT}px) {
        font-size: ${remCalc(20)};
    }
`;

/**
 * Tag
 */
export const DateDay = styled.div`
    ${dateStyles};
`;

/**
 * Jahr
 */
export const DateYear = styled.div`
    ${dateStyles};
    margin: 0;
`;

/**
 * ImageWrapper um das Bild herum
 */
export const ImageWrapper = styled.div`
    width: 100%;
    position: relative;
    z-index: 1;

    @media (min-width: ${ELEMENT_BREAKPOINT}px) {
        width: 40%;
        flex-shrink: 0;
    }

    > div {
        height: 100%;
    }

    img {
        min-height: ${remCalc(180)};
        height: 100%;
    }
`;

/**
 * Content - innerer Wrapper
 */
export const Content = styled.div`
    padding: 1em 1em 1.2em;
    @media (min-width: ${ELEMENT_BREAKPOINT}px) {
        align-self: center;
        padding: 1.2em 1.5em 1.5em;
        margin-right: 0;
    }
`;

/**
 * Title des Teasers
 */
export const Headline = styled.span`
    ${vwMinMax({ cssProp: `fontSize`, minValue: 20, maxValue: 28 })};
    margin-bottom: 0.3em;
`;

/**
 * Content - innerer Wrapper
 */
export const Copy = styled.div`
    ${vwMinMax({ cssProp: `fontSize`, minValue: 16, maxValue: 20 })};
    line-height: 1.5em;

    > p {
        margin-bottom: 0.3em;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
`;

/**
 * Mehr erfahren Link
 */
export const MoreLink = styled.span`
    ${vwMinMax({ cssProp: `fontSize`, minValue: 16, maxValue: 20 })};
    display: block;
    color: ${color.primary};
    text-decoration: none;
    margin-top: 0.5em;
`;
