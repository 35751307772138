import { useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import { throttle } from 'throttle-debounce';

import { scrollToAnchor } from '@/utils';

import { Link } from './styled';
import { THRESHOLD } from './constants';

export const ScrollTop = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const handleScroll = () => {
        if (window && window.scrollY >= THRESHOLD) {
            setIsVisible(true);
            return;
        }
        setIsVisible(false);
    };

    const throttledHandleScroll = throttle(300, handleScroll);

    useEventListener(`scroll`, throttledHandleScroll);

    return <Link href="#top" $isVisible={isVisible} title="Nach oben scrollen" onClick={scrollToAnchor} />;
};
