import styled, { css } from 'styled-components';

import { gapable, vwMinMax } from '@/style';
import { Gap } from '@/types';

/**
 * Simple Group Komponente, um Elemente nebeneinander zu platzieren
 */
export const Group = styled.div<{ $gutter?: string | [number, number]; $gap?: Gap }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    ${({ $gutter }) =>
        $gutter && typeof $gutter === `object`
            ? vwMinMax({ cssProp: `gap`, minValue: $gutter[0], maxValue: $gutter[1] })
            : css`
                  gap: ${$gutter ?? `20px`};
              `}

    ${gapable()}
`;
