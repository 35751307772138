import styled, { css } from 'styled-components';

import { gapable, vwMinMax } from '@/style';
import { Gap, ImagePosition, ImageWidth } from '@/types';

const elementBreakpoint = 560;

/**
 * Wrapper um alle Bilder herum
 */
export const ImagesWrapper = styled.div<{ $imageWidth: ImageWidth }>`
    flex-shrink: 0;
    width: 100%;
    position: relative;

    @media (min-width: ${elementBreakpoint}px) {
        width: ${({ $imageWidth }) => $imageWidth};
    }
`;

/**
 * Wrapper um ein einzelnes Bildelement
 */
export const ImageWrapper = styled.figure`
    width: 100%;

    > div {
        position: relative;
    }
`;

export const UIWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
`;

/**
 * Wrapper für die ganze TextImageBlock Komponente
 */
export const Wrapper = styled.div<{ $isTextFloating?: Boolean; $imagePosition?: ImagePosition; $gap?: Gap; $maxWidth?: string }>`
    ${vwMinMax({ cssProp: `fontSize`, minValue: 15, maxValue: 20 })};
    line-height: 1.75em;
    margin: 0 auto;
    max-width: ${({ $maxWidth }) => $maxWidth ?? `900px`};

    ${ImagesWrapper} {
        margin-bottom: 1em;
    }

    @media (min-width: ${elementBreakpoint}px) {
        ${({ $isTextFloating, $imagePosition }) =>
            $isTextFloating
                ? css`
                      display: block;
                      ${ImagesWrapper} {
                          margin-bottom: 1em;
                          margin-left: ${$imagePosition === `left` ? 0 : `1.5em`};
                          margin-right: ${$imagePosition === `right` ? 0 : `1.5em`};
                          float: ${$imagePosition};
                      }
                  `
                : css`
                      > ${ImageWrapper} {
                          margin-bottom: 0;
                      }
                      display: flex;
                      gap: 1.5em;
                      flex-direction: ${$imagePosition === `left` ? `row` : `row-reverse`};
                  `}
    }

    ${gapable()}

    > p:not(:last-child) {
        margin-bottom: 1rem;
    }
`;
