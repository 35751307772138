import styled from 'styled-components';

import arrowTop from '@/assets/img/navigation/arrow-top.svg';
import { color, hoverSpeed, position, pseudoStateClasses, remCalc } from '@/style';
/**
 * Link
 */
export const Link = styled.a<{ $isVisible: boolean }>`
    ${position({ bottom: `50vh`, right: `0`, left: `auto` }, `fixed`)};
    background-color: ${color.primary};
    background-image: url(${arrowTop});
    background-repeat: no-repeat;
    background-size: ${remCalc(18, 12)};
    background-position: center center;
    box-shadow: 0 0 ${remCalc(2, 2)} rgba(0, 0, 0, 0.1);
    border-top-left-radius: 20%;
    border-bottom-left-radius: 20%;
    display: block;
    height: ${remCalc(40)};
    ${({ $isVisible }) => ($isVisible ? `opacity: 1; pointer-events: auto;` : `opacity: 0; pointer-events: none;`)};
    width: ${remCalc(40)};
    transition: opacity ${hoverSpeed};
    transform: translateY(-50%);
    z-index: 1001;
    ${pseudoStateClasses(`background-color: ${color.secondary}`)};
`;
