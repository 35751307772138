import { MouseEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { SwipeableHandlers, SwipeEventData, useSwipeable } from 'react-swipeable';

import { AUTOPLAY_INTERVAL } from '@/constants';

type Direction = 'prev' | 'next';

interface UseSliderParams<T> {
    /** Element innerhalb des Sliders */
    items: T[];
    /** Flag, ob es Autopla gibt */
    hasAutoplay?: boolean;
    /** Geschwindigkeit / Standzeit für das autoplay */
    autoplayInterval?: number;
}

interface UseSliderReturn {
    /** Das aktuell aktive Bild */
    activeItemIndex: number;
    /** Methode, um den Slider nach vorne / zurück zu schalten */
    changeImage: (direction: Direction) => void;
    /**  Handler,  für */
    onPrevOrNextButtonClick: (evt: MouseEvent<HTMLButtonElement>) => void;
    /** Handler, die für das Swiping verwendet werden können */
    swipeHandlers: SwipeableHandlers;
}

/**
 * Hook, der die Basis für einen Bild-Slider bildet
 * @param param.elements Die Elemente des Sliders (als ReactNode)
 * @param param.hasAutoplay Flag, ob Autplay verwendet werden soll
 * @returns Objekt mit Einzelbestandteilen, um einen Slider zu bauen
 */
export const useSlider = <T = ReactNode>({
    items,
    hasAutoplay,
    autoplayInterval = AUTOPLAY_INTERVAL
}: UseSliderParams<T>): UseSliderReturn => {
    const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
    const [isAutoplayRunning, setIsAutoplayRunning] = useState<boolean>(hasAutoplay ?? false);
    const autoplayTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const killAutoplay = (isUnmounting: boolean = false) => {
        if (!isAutoplayRunning && !autoplayTimeoutRef.current) {
            return;
        }

        if (!isUnmounting) {
            setIsAutoplayRunning(false);
        }

        if (autoplayTimeoutRef.current) {
            clearInterval(autoplayTimeoutRef.current);
            autoplayTimeoutRef.current = null;
        }
    };

    /**
     * Wechselt das Bild der Homestage
     * @param direction "Richtung" des Bildwechsels
     */
    const changeImage = useCallback(
        (direction: 'next' | 'prev') => {
            const imageCount = items.length - 1;
            const getNextImageIndex = () => {
                if (direction === `next`) {
                    if (activeItemIndex + 1 <= imageCount) {
                        return activeItemIndex + 1;
                    }

                    return 0;
                }

                // Für directon === 'prev'
                if (activeItemIndex - 1 >= 0) {
                    return activeItemIndex - 1;
                }

                return imageCount;
            };

            setActiveItemIndex(getNextImageIndex());
        },
        [activeItemIndex, items.length]
    );

    /**
     * Listener für den Klick auf die ArrowButtons
     * @param evt Klick-Event
     */
    const onPrevOrNextButtonClick = (evt: MouseEvent<HTMLButtonElement>) => {
        const direction: Direction = evt.currentTarget.getAttribute(`data-direction`) as Direction;
        // Autoplay killen, wenn Klick erfolgt ist
        killAutoplay();
        changeImage(direction);
        evt.currentTarget.blur();
    };

    /**
     * Listener für Swipe
     * @param evt SwipeEventData
     */
    const onSwiped = (evt: SwipeEventData) => {
        killAutoplay();
        if (evt.deltaX > 0) {
            changeImage(`prev`);
        } else {
            changeImage(`next`);
        }
    };

    /**
     * Handler für Swipeable
     */
    const swipeHandlers = useSwipeable({ onSwiped });

    useEffect(() => {
        if (hasAutoplay && isAutoplayRunning) {
            autoplayTimeoutRef.current = setTimeout(() => {
                changeImage(`next`);
            }, autoplayInterval);
        }
    }, [hasAutoplay, autoplayInterval, changeImage, isAutoplayRunning]);

    useEffect(
        () => () => {
            killAutoplay(true);
        },
        // eslint-disable-next-line
        []
    );

    return { activeItemIndex, changeImage, onPrevOrNextButtonClick, swipeHandlers };
};
