import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

import overview from '@/assets/img/navigation/overview.svg';
import { color, hoverSpeed, mq, remCalc, vwMinMax } from '@/style';

/**
 * Icon für den Übersichts-Link
 */
export const Icon = styled.div`
    background: ${color.primary} url(${overview}) no-repeat;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    height: ${remCalc(35)};
    width: ${remCalc(35)};

    @media ${mq.large} {
        margin-right: 0.75em;
        height: ${remCalc(30)};
        width: ${remCalc(30)};
    }
`;

/**
 * Link für die Übersicht
 */
export const Link = styled(GatsbyLink)`
    color: ${color.black};
    text-decoration: none;
    font-size: ${remCalc(18)};
    transition: color ${hoverSpeed};

    &:hover {
        color: ${color.primary};
        ${Icon} {
            background-color: ${color.secondary};
        }
    }
    ${vwMinMax({ cssProp: `marginBottom`, minValue: 15, maxValue: 20 })};
    align-items: center;
    display: inline-flex;
    left: 50%;
    position: relative;
    transform: translateX(-50%);

    justify-content: center;
    text-align: center;

    @media ${mq.medium} {
        ${vwMinMax({ cssProp: `marginBottom`, minValue: 25, maxValue: 35 })};
    }

    > span {
        display: none;
        @media ${mq.large} {
            display: flex;
        }
    }
`;
