import { ReactNode } from 'react';
import { Link } from 'gatsby';

import { scrollToAnchor } from '@/utils';

import { Button as StyledButton, Wrapper } from './styled';

export interface ButtonProps {
    children: ReactNode;
    wrapperClassName?: string;
    isCentered?: boolean;
    onClick?: () => void;
    to?: string;
}

/**
 * LinkButton Komponente
 * @param {*} props
 */
export const Button = ({ children, to, onClick, isCentered, wrapperClassName }: ButtonProps) => {
    const getButton = () => {
        const isInternalLink = to ? /^\/(?!\/)/.test(to) : false;
        const anchor = to && to.charAt(0) === `#`;

        /* Button-Links mit callback only */
        if (onClick) {
            return (
                <StyledButton
                    onClick={evt => {
                        onClick();
                        evt.currentTarget.blur();
                    }}
                >
                    {children}
                </StyledButton>
            );
        }

        /* Externe Links */
        if (!isInternalLink && !anchor) {
            return (
                <StyledButton href={to} target="_blank" as="a">
                    {children}
                </StyledButton>
            );
        }

        /* Normale, interne Gatsby-Links */
        if (isInternalLink && to) {
            return (
                <StyledButton as={Link} to={to} onClick={onClick}>
                    {children}
                </StyledButton>
            );
        }

        /* Anchorsprünge */
        if (anchor) {
            return (
                <StyledButton href={to} as="a" onClick={scrollToAnchor}>
                    {children}
                </StyledButton>
            );
        }

        return `Kein passender Button-Type`;
    };

    return (
        <Wrapper className={wrapperClassName} $isCentered={isCentered}>
            {getButton()}
        </Wrapper>
    );
};
