/**
 * Farben
 */
export const color = {
    primary: `#A5B339`,
    secondary: `#97A438`,
    black: `#000000`,
    white: `#ffffff`,
    grayLight: `#eeeeee`,
    grayMedium: `#dfdfdf`,
    grayDark: `#1a1a1a`,

    // state colors
    success: `#3adb76`,
    warning: `#ffae00`,
    alert: `#cc4b37`
};

export type Color = keyof typeof color;

/**
 * Hover Transition Speed
 */
export const hoverSpeed = `0.25s`;

/**
 * Schriften
 */
export const fontFamily = `Arial, sans-serif`;

/**
 * Breakpoints
 */
export const breakpoint = {
    small: 640,
    medium: 1024,
    large: 1200,
    xlarge: 1600,
    xxlarge: 1920,
    xxxlarge: 2560
};

export type Breakpoint = keyof typeof breakpoint;
