import { useLocation } from '@gatsbyjs/reach-router';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

/**
 * Hook um aktuellen Hash des Browsers zu erhalten oder zu setzen
 * @returns
 */
export const useHash = (): [string | null, Dispatch<SetStateAction<string | null>>] => {
    const { hash: hashInURL } = useLocation();
    const [hash, setHash] = useState<string | null>(hashInURL.slice(1));
    const isFirstRender = useRef<boolean>(true);

    useEffect(() => {
        if (!isFirstRender.current && hash && `#${hash}` !== hashInURL && window) {
            window.location.hash = hash;
            return;
        }
        isFirstRender.current = false;
        // Nächste Zeile deaktiviert, weil NUR bei hash change reagiert werden soll
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash]);

    useEffect(() => {
        setHash(hashInURL.slice(1));
    }, [hashInURL]);

    return [hash, setHash];
};
