/**
 * Helferlein um leere Strings zu erkennen
 * Liefert "true", wenn der String leer oder "null" ist
 *
 * 0 oder " " sind leere string, "0" nicht
 * @param str
 * @returns Flag, ob string leer ist oder nicht
 */

export function isNullOrEmptyString(str: unknown): str is undefined | null | '' {
    return typeof str === `string` ? !(str as string)?.trim() : true;
}
