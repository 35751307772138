import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { Wrapper } from './styled';

/**
 * Logo Komponente Komponente
 */
export const Logo = () => (
    <Wrapper>
        <Link to="/">
            <StaticImage
                width={320}
                src="../../../assets/img/logo.png"
                alt="Mantrailer Neckar-Alb. Verein für Personensuche und Rettungshunde"
                placeholder="none"
            />
        </Link>
    </Wrapper>
);
