import { useAuth0, User } from '@auth0/auth0-react';
import { ReactNode } from 'react';

import { Button, Group, Section } from '@/components/common';
import { color } from '@/style';

import { LogoutButton } from '../LogoutButton';

import { CenterContainer } from './styled';

const Unverified = ({ user }: { user: User }) => (
    <Section bgColor={color.grayLight} isAnimated={false}>
        <CenterContainer>
            <h1>Mitgliederbereich</h1>
            <p>
                Die Verifizierung deiner E-Mail-Adresse ist noch ausstehend. <br />
                Bitte überprüfe deinen Posteingang für{` `}
                <strong>{user.email}</strong>, kontrolliere ggf. auch deinen Spam-Ordner.
            </p>
            <Group>
                <Button onClick={() => window.location.reload()}> Seite neu laden</Button>
                <LogoutButton />
            </Group>
        </CenterContainer>
    </Section>
);

const AuthError = () => (
    <Section bgColor={color.grayLight} isAnimated={false}>
        <CenterContainer>
            <h1>Es ist ein Fehler aufgetreten</h1>
            <Group>
                <Button onClick={() => window.location.reload()}> Seite neu laden</Button>
                <LogoutButton />
            </Group>
        </CenterContainer>
    </Section>
);

/**
 * Überprüft, ob der User bereits seine E-Mail verifiziert hat
 * Ist dies nicht der Fall, wird eine Meldung angezeigt, ansonsten der eigentliche Inhalt
 * Gibt zudem einen Fehler aus, wenn beim anmelden etwas schief gelaufen ist
 */
export const Verification = ({ children }: { children: ReactNode }) => {
    const { user, isAuthenticated } = useAuth0();

    if (!user || !isAuthenticated) {
        return <AuthError />;
    }

    return user?.email_verified ? <>{children}</> : <Unverified user={user} />;
};
