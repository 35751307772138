import { ReactNode } from 'react';

import { Gap, HeadlineLevel } from '@/types';
import { isDefined } from '@/utils';

import { Button } from '../Button';

import { Copy, Headline, Wrapper } from './styled';

interface IntroProps {
    headline: {
        text: ReactNode;
        as?: HeadlineLevel;
        isDecolineHidden?: boolean;
    };
    link?: {
        to: string;
        text: string;
        callback?: () => void;
    };
    copy?: ReactNode;
    textAlignLeft?: boolean | { breakpoint: number };
    gap?: Gap;
    isSmall?: boolean;
}

/**
 * Intro Komponente
 */
export const Intro = ({ link, copy, headline, textAlignLeft, gap, isSmall }: IntroProps) => (
    <Wrapper $textAlignLeft={textAlignLeft} $gap={gap}>
        <Headline as={headline.as ?? `h2`} $isDecolineHidden={headline.isDecolineHidden} $isSmall={isSmall}>
            {headline.text}
        </Headline>
        <Copy $small={isSmall} $marginBottom={isDefined(link)}>
            {copy}
        </Copy>
        {link && (
            <Button to={link.to} onClick={link.callback}>
                {link.text}
            </Button>
        )}
    </Wrapper>
);
