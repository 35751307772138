import styled, { css } from 'styled-components';

import { color, hoverSpeed, pseudoStateClasses, vwMinMax } from '@/style';

/**
 * Wrapper um den LinkButton
 */
export const Wrapper = styled.div<{ $isCentered?: boolean }>`
    ${({ $isCentered }) =>
        $isCentered &&
        css`
            text-align: center;
        `};
`;

/**
 * Der Eigentliche Button
 */
export const Button = styled(`button`)`
    ${vwMinMax({ cssProp: `fontSize`, minValue: 18, maxValue: 25 })}

    border: 2px solid ${color.grayDark};
    background: transparent;
    color: ${color.grayDark};
    cursor: pointer;
    display: inline-block;
    padding: 0.42em 0.95em 0.33em 0.95em;
    text-decoration: none;
    text-align: center;
    transition:
        color ${hoverSpeed},
        border-color ${hoverSpeed},
        background-color ${hoverSpeed};

    ${pseudoStateClasses(`
        color: ${color.white};
        background-color: ${color.secondary};
    `)};
`;
