/* eslint-disable camelcase */

import { GatsbyImage } from 'gatsby-plugin-image';

import { TrainingDiaryQueryData } from '@/types';

import { isNullOrEmptyString } from '../isNullOrEmptyString';
import { withoutEdgesAndNodes } from '../withoutEdgedAndNodes';

/**
 * Transfomiert Daten der Team-Query (allPrismicTeam) in ein renderbares Format
 */
export const transformTrainingDiaryQueryData = ({ result: { edges } }: TrainingDiaryQueryData) =>
    withoutEdgesAndNodes(edges).map(({ data, id }) => ({
        datetime: data?.datetime,
        id,
        isGrouptrail: data.grouptrail,
        isNegativeTrail: data.negative_trail,
        location: data.location,
        locationGeopoint: data.location_geopoint,
        organizers:
            data.organizers && data.organizers.length > 0
                ? data.organizers?.map(({ organizer }) =>
                      organizer
                          ? {
                                firstname: organizer?.document.data.firstname,
                                lastname: organizer?.document.data.name
                            }
                          : null
                  )
                : null,
        remarks: !isNullOrEmptyString(data.remarks?.text) ? data.remarks?.text : null,
        temperature: data.temperature || null,
        trailCreation: data.trail_creation,
        trailLength: data.trail_length,
        weather: !isNullOrEmptyString(data.weather) ? data.weather : null,
        teams: data.teams
            ?.map(({ first_started, team }) => {
                const { members, dog } = team.document.data;
                return {
                    hasStartedFirst: first_started,
                    name: `${members.map(({ member }) => member.document.data.firstname).join(`, `)} & ${dog.document.data.name}`
                };
            })
            ?.sort((a, b) => (a.name <= b.name ? -1 : 1)),
        guests: data.guests
            .map(guest => ({ dog: guest.dog, dogHandler: guest.dog_handler }))
            ?.sort((a, b) => (a.dogHandler <= b.dogHandler ? -1 : 1)),
        images: data.images.map(({ image }, index) => (
            <GatsbyImage key={index} image={image.gatsbyImageData} alt={image.alt ?? `Galeriebild`} />
        ))
    }));
