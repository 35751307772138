import { ReactNode } from 'react';

import { Gap } from '@/types';

import { Wrapper } from './styled';

interface GridProps {
    children: ReactNode;
    cols?: number | string;
    gap?: Gap;
    gutter?: number | [number, number];
    gridFrom?: number | string;
    id?: string;
}

export const Grid = ({ children, cols = 2, gap = null, gridFrom = 800, gutter, id }: GridProps) => (
    <Wrapper id={id} $noCols={Number(cols)} $gap={gap} $gridFrom={Number(gridFrom)} $gutter={gutter}>
        {children}
    </Wrapper>
);
