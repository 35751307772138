import { ReactNode } from 'react';

import { color } from '@/style';

import { OverviewLink } from '../../common/OverviewLink';
import { Section } from '../../common/Section';

import { ImageWrapper, Wrapper } from './styled';

interface ArticleWrapperProps {
    children: ReactNode;
    title: string;
    headerImage: ReactNode;
}

/**
 * Wrappt einen Blog-Artikel und steuert Breiten und etwaige Abstände
 */
export const ArticleWrapper = ({ children, headerImage, title }: ArticleWrapperProps) => (
    <Section bgColor={color.grayLight}>
        <Wrapper>
            <OverviewLink />
            <h1>{title}</h1>
            <ImageWrapper>{headerImage}</ImageWrapper>
            {children}
        </Wrapper>
    </Section>
);
