import { ReactNode } from 'react';

interface DataItemProps {
    title: string;
    children: ReactNode;
}

/**
 * Stellt einen "Datenblock" innerhalb eines Tagebucheintrags dar
 * @param param0
 */
export const DataItem = ({ title, children }: DataItemProps) => (
    <div>
        <strong>{title}</strong> <br />
        {children}
    </div>
);
