import styled from 'styled-components';
import { rgba } from 'polished';

import { color, pseudoStateClasses } from '@/style';

/**
 * Download List for the "Dokumente" page in the members area
 */
export const StyledDownloadList = styled.ul`
    display: flex;
    flex-direction: column;
    list-style-type: none;
    gap: 0.5rem;
    list-style-position: inside;
    padding: 0;

    li {
        display: flex;
        gap: 0.5rem;
        padding: 0.5rem 0;
        &:not(:last-child) {
            border-bottom: 1px solid ${rgba(color.black, 0.2)};
        }
    }

    a {
        ${pseudoStateClasses(`color: ${color.secondary}`)};
        display: flex;
        width: 100%;
    }
`;
