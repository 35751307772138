import styled from 'styled-components';
import { darken } from 'polished';

import { color, vwMinMax } from '@/style';

export const Wrapper = styled.nav`
    ${vwMinMax({ cssProp: `padding`, minValue: [0, 15], maxValue: [0, 112] })};
    align-items: center;
    border-bottom: 1px solid ${darken(0.05, color.grayMedium)};
    border-top: 1px solid ${darken(0.05, color.grayMedium)};
    margin: 1.5rem auto 0;
    width: 100%;
`;

export const Nav = styled.div`
    max-width: 1150px;
    padding: 8px 0;
    display: flex;
    gap: 2em;
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;

    > a {
        flex-shrink: 0;
    }
`;
