import styled from 'styled-components';

import { Gap } from '@/types';
import { gapable } from '@/style';

/** Stellt einen simplen Textblock (hauptsächlich innerhalb eines Blogartikels) zur Verfügung */
export const TextBlock = styled.div<{ $gap?: Gap; $isBold?: Boolean; $maxWidth?: string }>`
    font-weight: ${({ $isBold }) => ($isBold ? `bold` : `normal`)};
    margin: 0 auto;
    max-width: ${({ $maxWidth }) => $maxWidth ?? `900px`};
    ${gapable(`1rem`)}

    p {
        margin-bottom: 2rem;
    }
`;
