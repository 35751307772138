import styled from 'styled-components';

import { vwMinMax, color, remCalc } from '@/style';

/**
 * Wrapper für den Header
 */
export const Wrapper = styled.div<{ showDivider?: boolean }>`
    ${vwMinMax({ cssProp: `padding`, minValue: [15, 15], maxValue: [30, 112] })};
    ${({ showDivider }) => (showDivider ? `border-bottom: 1px solid ${color.grayLight}` : `border-bottom: 1px solid transparent`)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${remCalc(20)};
`;
