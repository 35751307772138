import AnimateHeight from 'react-animate-height';
import { useState } from 'react';

import { transformTrainingDiaryQueryData } from '@/utils';
import arrowDown from '@/assets/img/navigation/arrow-down.svg';
import { useSlider } from '@/hooks';

import { DataItem } from '../DataItem';

import { ImagesWrapper, List, MetaData, Wrapper, Headline, Inner, Divider, StyledLeftArrowButton, StyledRightArrowButton } from './styled';

const locale = `DE-de`;
const timeZone = `Europe/Berlin`;
const dateStyle = `medium`;

interface DiaryEntryProps {
    data: ReturnType<typeof transformTrainingDiaryQueryData>[0];
    isInitiallyOpen?: boolean;
}

/**
 * Element für einen Eintrag im Trainingstagebuch
 */
export const DiaryEntry = ({ data, isInitiallyOpen }: DiaryEntryProps) => {
    const [height, setHeight] = useState<'auto' | number>(isInitiallyOpen ? `auto` : 0);
    const openEntry = () => setHeight(height === 0 ? `auto` : 0);
    const id = `diary-entry-${data.id}`;
    const isExpanded = height !== 0;
    const images = data.images ?? [];

    const { activeItemIndex: activeImageIndex, onPrevOrNextButtonClick, swipeHandlers } = useSlider({ items: images });

    const firstStartedTeams = data.teams?.filter(team => team.hasStartedFirst);

    return (
        <Wrapper>
            <Headline $isArrowRotated={isExpanded} onClick={openEntry}>
                Training in {data.location}
                {data.datetime && (
                    <>
                        {` `}am{` `}
                        {new Date(data.datetime).toLocaleDateString(locale, { dateStyle, timeZone })}
                        {` `}({new Date(data.datetime).toLocaleDateString(locale, { weekday: `short` })}) um {` `}
                        {new Date(data.datetime).toLocaleTimeString(locale, { timeZone })} Uhr
                    </>
                )}
                <img src={arrowDown} />
            </Headline>

            <AnimateHeight id={id} duration={500} height={height}>
                <Inner>
                    {data.images && data.images?.length > 0 && (
                        <ImagesWrapper {...swipeHandlers}>
                            {data.images.length > 1 && (
                                <>
                                    <StyledLeftArrowButton data-direction="prev" onClick={onPrevOrNextButtonClick} />
                                    <StyledRightArrowButton direction="right" data-direction="next" onClick={onPrevOrNextButtonClick} />
                                </>
                            )}

                            {images[activeImageIndex]}
                        </ImagesWrapper>
                    )}

                    <MetaData>
                        {data.organizers && (
                            <DataItem title="Organisiert von">
                                {data.organizers.map(organizer => `${organizer?.firstname} ${organizer?.lastname}`).join(`, `)}
                            </DataItem>
                        )}

                        {data.weather && <DataItem title="Wetterlage">{data.weather}</DataItem>}

                        {data.temperature && <DataItem title="Temperatur">{data.temperature}° C</DataItem>}

                        {data.trailLength && <DataItem title="Länge des Trails">{data.trailLength}</DataItem>}

                        {data.trailCreation && (
                            <DataItem title="Trail gelegt am">
                                {new Date(data.trailCreation).toLocaleDateString(locale, { dateStyle, timeZone })}
                            </DataItem>
                        )}

                        <DataItem title="Negativtrail">{data.isNegativeTrail ? `Ja` : `Nein`}</DataItem>

                        <DataItem title="Gruppentrail">{data.isGrouptrail ? `Ja` : `Nein`}</DataItem>

                        {Boolean(firstStartedTeams?.length) && (
                            <DataItem title="Als erstes gestartet">
                                {firstStartedTeams?.map(
                                    ({ name }, index) =>
                                        `${name}${firstStartedTeams.length > 1 && index < firstStartedTeams.length - 1 ? `, ` : ``}`
                                )}
                            </DataItem>
                        )}
                    </MetaData>

                    <Divider />

                    <DataItem title="Anwesende Teams">
                        <List>{data.teams?.map(({ name }) => <li key={name}>{name}</li>)}</List>
                    </DataItem>

                    {data.guests.length > 0 && (
                        <>
                            <Divider />
                            <DataItem title="Anwesende Gäste">
                                <List>
                                    {data.guests.map(({ dogHandler, dog }) => (
                                        <li key={dogHandler}>
                                            {dogHandler} mit {dog}
                                        </li>
                                    ))}
                                </List>
                            </DataItem>
                        </>
                    )}

                    {data.remarks && (
                        <>
                            <Divider />
                            <DataItem title="Anmerkungen">{data.remarks}</DataItem>
                        </>
                    )}
                </Inner>
            </AnimateHeight>
        </Wrapper>
    );
};
