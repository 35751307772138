import { ReactNode } from 'react';

import { Section } from '@/components/common';
import { color } from '@/style';

import { Navbar } from '../Navbar';
import { Verification } from '../Verification';

import { Inner } from './styled';

/**
 * Wrapper für den Mitgliederbereich, stellt Navigation und Verifizierungslogik bereit
 */
export const MembersWrapper = ({ children }: { children: ReactNode }) => (
    <Verification>
        <Navbar />
        <Section bgColor={color.grayLight} isAnimated={false}>
            <Inner>{children}</Inner>
        </Section>
    </Verification>
);
