import { LinkGetProps } from '@gatsbyjs/reach-router';

/**
 * Helfer, um Navigationslinks mit den korrekten Klassen auszustatten
 */
export const getNavLinkProps = ({ isPartiallyCurrent, href, isCurrent }: LinkGetProps) => {
    const props = { className: `navlink navlink--active` };
    if (href === `/`) {
        return isCurrent ? props : {};
    }
    return isPartiallyCurrent ? props : {};
};
