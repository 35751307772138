import { MouseEvent } from 'react';

/**
 * Helferlein um smooth an eine id zu scrollen
 * id muss als #id im target des triggers stehen
 * @param evt MouseEvent
 */
export const scrollToAnchor = (evt: MouseEvent<HTMLElement>) => {
    evt.preventDefault();
    evt.currentTarget.blur();
    const id = evt.currentTarget?.getAttribute(`href`)?.substring(1);
    const target = document.getElementById(`${id}`);
    if (target && window) {
        window.scroll({
            top: target.getBoundingClientRect().top + window.pageYOffset,
            behavior: `smooth`
        });
    }
};
