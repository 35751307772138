import styled, { css } from 'styled-components';

import { breakpoint, color, gapable, position, remCalc, vwMinMax } from '@/style';
import { Gap } from '@/types';
import quote from '@/assets/img/quote.svg';

import { ELEMENT_BREAKPOINT } from './constants';

/**
 * Wrapper um das Element herum
 */
export const Wrapper = styled.div<{ $isStyledAsBox?: boolean; $gap?: Gap }>`
    ${vwMinMax({ cssProp: `marginBottom`, minValue: 20, maxValue: 30 })};
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    margin-left: auto;
    margin-right: auto;
    max-width: ${remCalc(400)};

    &:last-of-type {
        margin-bottom: 0;
    }

    @media (min-width: ${ELEMENT_BREAKPOINT}px) {
        ${({ $isStyledAsBox }) =>
            $isStyledAsBox
                ? css`
                      flex-direction: column-reverse;
                      justify-content: flex-end;
                      margin-bottom: 0;
                      max-width: none;
                  `
                : css`
                      align-items: stretch;
                      flex-direction: row;
                      &:nth-child(odd) {
                          flex-direction: row-reverse;
                      }
                  `};
    }

    /* VW-Settings außerhalb der MQ */
    ${({ $isStyledAsBox }) =>
        !$isStyledAsBox &&
        css`
            ${vwMinMax({ cssProp: `maxWidth`, minValue: 850, maxValue: 1600, minViewport: breakpoint.medium })};
        `}

    ${gapable()};
`;

/**
 * Die Textbox
 */
export const TextWrapper = styled.div`
    border: ${remCalc(2)} solid ${color.primary};
    display: flex;
    align-items: center;
    position: relative;
    width: 90%;
    margin: 0 auto;

    @media (max-width: ${ELEMENT_BREAKPOINT - 1}px) {
        border-top: none;
    }

    @media (min-width: ${ELEMENT_BREAKPOINT}px) {
        width: 65%;
        .quotebox--boxstyled & {
            align-items: flex-start;
            height: 100%;
            width: 100%;
            border-top: none;
        }
    }
`;

/**
 * Innerer Wrapper für das Bild
 */
export const TextWrapperInner = styled.div`
    padding: 6% 10% 6% 16%;
    max-width: 100%;

    .quotebox--boxstyled & {
        ${vwMinMax({ cssProp: `padding`, minValue: [20, 20, 20, 50], maxValue: [40, 40, 40, 120], minViewport: ELEMENT_BREAKPOINT })};
    }

    @media (min-width: ${ELEMENT_BREAKPOINT}px) {
        padding: 6% 35% 6% 15%;
        .quotebox:not(.quotebox--boxstyled):nth-child(odd) & {
            padding: 6% 10% 6% 40%;
        }
    }
`;

/**
 * Headline der QuoteBox
 */
export const Headline = styled.span`
    ${vwMinMax({ cssProp: `fontSize`, minValue: 22, maxValue: 40 })};
    margin-bottom: 0.3em;
    color: ${color.primary};
    position: relative;
    word-wrap: break-word;
    &::before {
        ${vwMinMax({ cssProp: `height`, minValue: 15, maxValue: 40 })};
        ${vwMinMax({ cssProp: `width`, minValue: 20, maxValue: 53 })};
        ${position({ top: `0`, left: `0` })};
        display: block;
        background: url(${quote});
        margin-top: 0.3em;
        background-repeat: no-repeat;
        background-size: 97%;
        content: '';
        transform: translateX(-150%);

        @media (min-width: ${ELEMENT_BREAKPOINT}px) {
            margin-top: 0.15em;
        }
    }
`;

/**
 * Copytext der QuoteBox
 */
export const Copy = styled.p`
    ${vwMinMax({ cssProp: `fontSize`, minValue: 15, maxValue: 20 })};
    margin-bottom: 0.8em;
    .quotebox--boxstyled & {
        margin-bottom: 0;
    }
`;

/**
 * Element für den Urheber des Zitats
 */
export const Author = styled.strong<{ asHeadline?: boolean; withoutLine?: boolean }>`
    display: flex;
    align-items: center;
    ${vwMinMax({ cssProp: `fontSize`, minValue: 15, maxValue: 20 })};

    ${({ asHeadline }) =>
        asHeadline &&
        css`
            ${vwMinMax({ cssProp: `fontSize`, minValue: 22, maxValue: 25 })};
            display: block;
            font-weight: normal;
            margin: 0 auto 0.25em;
            order: 3;
            text-align: center;
        `};

    ${({ withoutLine }) =>
        !withoutLine &&
        css`
            &:before {
                ${vwMinMax({ cssProp: `width`, minValue: 20, maxValue: 30 })};
                display: block;
                content: '';
                background-color: ${color.primary};
                margin-right: ${remCalc(10)};
                height: ${remCalc(3)};
            }
        `};
`;

/**
 * ImageWrapper um das Bild herum
 */
export const ImageWrapper = styled.div`
    z-index: 1;

    @media (min-width: ${ELEMENT_BREAKPOINT}px) {
        flex-direction: row;
        align-items: stretch;
        width: 40%;

        .quotebox--boxstyled & {
            width: 100%;
        }
    }
`;

/**
 * Innerer Wrapper für das Bild
 */
export const ImageWrapperInner = styled.div`
    @media (min-width: ${ELEMENT_BREAKPOINT}px) {
        position: relative;
        left: -40%;
        top: 50%;
        padding: 5% 0;
        transform: translateY(-50%);
        width: 140%;

        .quotebox:nth-child(odd) & {
            left: 0;
        }

        .quotebox--boxstyled & {
            width: 100%;
            left: 0;
            top: 0;
            transform: translateY(0);
            padding: 0;
        }
    }

    img {
        height: 100%;
    }
`;
