import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { mq } from '@/style';
import { OFF_CANVAS_ACTIVE_CLASSNAME } from '@/constants';

import { Navigation } from '../Navigation';
import { OffCanvasToggler } from '../OffCanvasToggler/OffCanvasToggler';
import { Logo } from '../Logo';

import { Wrapper } from './styled';

interface HeaderProps {
    showDivider?: boolean;
    id?: string;
}

/**
 * Header samt Logo und Navigation
 */
export const Header = ({ showDivider, id }: HeaderProps) => {
    const [isOffCanvasActive, setIsOffCanvasActive] = useState<boolean>(false);
    const matches = useMediaQuery(mq.medium);

    const openOffCanvas = () => {
        if (!matches) {
            const html = document.querySelector(`html`);
            html?.classList.add(OFF_CANVAS_ACTIVE_CLASSNAME);
            setIsOffCanvasActive(true);
        }
    };

    const closeOffCanvas = useCallback(() => {
        const html = document.querySelector(`html`);
        html?.classList.remove(OFF_CANVAS_ACTIVE_CLASSNAME);
        setIsOffCanvasActive(false);
    }, []);

    useEffect(() => {
        closeOffCanvas();
    }, [matches, closeOffCanvas]);

    return (
        <Wrapper showDivider={showDivider} id={id}>
            <Logo />
            <Navigation offCanvasActive={isOffCanvasActive} closeOffCanvas={closeOffCanvas} />
            <OffCanvasToggler onClick={openOffCanvas} />
        </Wrapper>
    );
};
