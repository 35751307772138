import { CSSProperties } from 'styled-components';

import { breakpoint } from './variables';
import { remCalc } from './remCalc';

interface Params {
    cssProp: keyof CSSProperties;
    minValue: number | number[];
    maxValue: number | number[];
    minViewport?: number;
    maxViewport?: number;
    noMobileMQ?: boolean;
    usePixel?: boolean;
    important?: boolean;
}

const kebabize = (str: string) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? `-` : ``) + $.toLowerCase());

/**
 * Mixin um zwischen zwei Breakpoints Pixelwerte fließend in Abhängigkeit des Viewports zu skalieren
 * @param params Objekt mit named params
 * @returns cssRule
 */
export const vwMinMax = ({
    cssProp,
    minValue,
    maxValue,
    minViewport = breakpoint.small,
    maxViewport = breakpoint.xxxlarge,
    noMobileMQ = false,
    usePixel,
    important
}: Params) => {
    let max;
    let min;
    let minMaxValue;
    const vwOrVh = cssProp === `top` || cssProp === `bottom` ? `vh` : `vw`;
    const widthOrHeight = cssProp === `top` || cssProp === `bottom` ? `height` : `width`;
    const viewportIncrease = maxViewport - minViewport;

    const minMax = (min: number, max: number) => {
        const increase = max - min;
        return `
            calc(${(increase / viewportIncrease) * 100}${vwOrVh} + ${min - (increase / viewportIncrease) * minViewport}px)
        `;
    };

    if (typeof minValue === `number` && typeof maxValue === `number`) {
        max = usePixel ? `${maxValue}px` : remCalc(maxValue);
        min = usePixel ? `${minValue}px` : remCalc(minValue);
        minMaxValue = minMax(minValue, maxValue);
    }

    // Wenn es multiple Werte sind, ggf. für padding etc.
    if (Array.isArray(minValue) && Array.isArray(maxValue)) {
        max = usePixel ? maxValue.map(value => `${value}px`).join(` `) : maxValue.map(value => remCalc(value)).join(` `);
        min = usePixel ? minValue.map(value => `${value}px`).join(` `) : minValue.map(value => remCalc(value)).join(` `);
        minMaxValue = minValue.map((value, index) => minMax(value, maxValue[index])).join(` `);
    }

    const mobileMQ = !noMobileMQ ? `${kebabize(cssProp)}: ${min}${important ? ` !important` : ``};` : ``;
    return `
       ${mobileMQ};
        @media (min-${widthOrHeight}: ${remCalc(minViewport)}) {
            ${kebabize(cssProp)}: ${minMaxValue}${important ? ` !important` : ``};
        }
        @media (min-${widthOrHeight}: ${remCalc(maxViewport)}) {
            ${kebabize(cssProp)}: ${max}${important ? ` !important` : ``};
        }
    `;
};
