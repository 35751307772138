import styled from 'styled-components';
import { rgba } from 'polished';

import { OFF_CANVAS_ACTIVE_CLASSNAME } from '@/constants';
import { color } from '@/style';

/**
 * Main als Wrapper für den content
 */
export const Main = styled.main`
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    .${OFF_CANVAS_ACTIVE_CLASSNAME} & {
        &:after {
            content: '';
            animation: fade-in 0.25s forwards;
            display: block;
            background-color: ${rgba(color.black, 0.5)};
            backdrop-filter: blur(1px);
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
        }
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`;

export const ErrorPre = styled.pre`
    color: red;
    display: block;
    white-space: break-spaces;
    margin-bottom: 40px;
`;
