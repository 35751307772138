import { Intro } from '@/components/common';
import downloadIcon from '@/assets/img/download.svg';

import { StyledDownloadList } from './styled';

interface DownloadListProps {
    items: {
        title: string;
        url: string;
    }[];
}

export const DownloadList = ({ items }: DownloadListProps) => (
    <>
        <Intro textAlignLeft headline={{ text: `Dokumente rund um den Verein`, isDecolineHidden: true }} gap={[5, 10]} />
        <StyledDownloadList>
            {items.map(item => (
                <li key={item.url}>
                    <img src={downloadIcon} />
                    <a download href={item.url}>
                        {item.title}
                    </a>
                </li>
            ))}
        </StyledDownloadList>
    </>
);
