import { Link } from 'gatsby';

import { membersRoutes } from '@/resources/membersRoutes';
import { getNavLinkProps } from '@/utils';

import { Nav, Wrapper } from './styled';

/**
 * Die Navigation des Mitgliederbereichs
 */
export const Navbar = () => (
    <Wrapper>
        <Nav id="membersNav">
            {membersRoutes.map(({ to, text }) => {
                const key = `members-nav-${text}`;
                return (
                    <Link key={key} className="navlink" to={to} getProps={getNavLinkProps}>
                        {text}
                    </Link>
                );
            })}
        </Nav>
    </Wrapper>
);
