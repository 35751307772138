import { ReactNode } from 'react';
import { Link } from 'gatsby';

import { Gap, HeadlineLevel, ImagePosition } from '@/types';

import { Content, Copy, DateDay, DateMonth, DateWrapper, DateYear, Headline, ImageWrapper, MoreLink, Wrapper } from './styled';

interface ArticleTeaserProps {
    image: ReactNode;
    imagePosition?: ImagePosition;
    headline: {
        level?: HeadlineLevel;
        text: string;
    };
    copy: ReactNode;
    gap?: Gap;
    to: string;
    date: string;
}

/**
 * Teaser für Blogeinträge
 */
export const ArticleTeaser = ({ copy, date: dateProp, gap, headline, image, to, imagePosition = `left` }: ArticleTeaserProps) => {
    const date = new Date(dateProp);
    return (
        <Link to={to}>
            <Wrapper $gap={gap} $isFlexReversed={imagePosition !== `left`}>
                <DateWrapper>
                    <DateDay>{date.toLocaleDateString(`de-DE`, { day: `2-digit` })}.</DateDay>
                    <DateMonth>{date.toLocaleDateString(`de-DE`, { month: `short` })}</DateMonth>
                    <DateYear>{date.getFullYear()}</DateYear>
                </DateWrapper>
                <ImageWrapper>{image}</ImageWrapper>
                <Content>
                    <Headline as={headline.level ? headline.level : `h2`}>{headline.text}</Headline>
                    <Copy>{copy}</Copy>
                    <MoreLink className="more-link">Mehr erfahren</MoreLink>
                </Content>
            </Wrapper>
        </Link>
    );
};
