/**
 * Navigationsstruktur der Hauptnavigation
 */
export const mainRoutes = [
    {
        text: `Start`,
        to: `/`
    },
    {
        text: `Unsere Teams`,
        to: `/unsere-teams`
    },
    {
        text: `Blog`,
        to: `/blog`
    },
    {
        text: `Mitgliederbereich`,
        to: `/mitgliederbereich`
    },

    {
        text: `Kontakt`,
        to: `#kontakt`
    }
];
