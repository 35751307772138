import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { color, mq, position, vwMinMax } from '@/style';
import { ArrowButton } from '@/components/common';

export const Headline = styled.button<{ $isArrowRotated: boolean }>`
    ${vwMinMax({ cssProp: `fontSize`, minValue: 18, maxValue: 25 })};
    line-height: 1.5;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > img {
        width: 1.5rem;
        transition: transform 0.25s;

        ${({ $isArrowRotated }) =>
            $isArrowRotated &&
            css`
                transform: rotate(180deg);
            `};
    }
`;

export const Wrapper = styled.div`
    background-color: ${color.white};
    box-shadow: 0 0 0.5rem 0 ${rgba(color.black, 0.14)};
    border-radius: 10px;
    padding: 1.25rem;

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
`;

export const Inner = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`;

export const ImagesWrapper = styled.figure`
    border: 1px solid ${color.grayMedium};
    background-color: ${color.grayMedium};
    display: flex;
    justify-content: center;
    margin-bottom: 0.75rem;
    position: relative;
    width: 100%;
`;

const arrowButtonStyles = css`
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
`;

export const StyledLeftArrowButton = styled(ArrowButton)`
    ${arrowButtonStyles}
    ${position({ left: `1rem` })}
`;

export const StyledRightArrowButton = styled(ArrowButton)`
    ${arrowButtonStyles}
    ${position({ right: `1rem` })}
`;

export const MetaData = styled.div`
    display: flex;
    column-gap: 2rem;
    row-gap: 0.5rem;
    flex-wrap: wrap;

    > div {
        width: calc(100% / 2 - 2rem);

        @media (min-width: 768px) {
            width: calc(100% / 3 - 2rem);
        }

        @media ${mq.medium} {
            width: calc(100% / 5 - 2rem);
        }
    }
`;

export const Divider = styled.div`
    height: 1px;
    background-color: ${color.grayMedium};
    width: 100%;
`;

export const List = styled.ul`
    column-count: 1;
    column-gap: 2rem;
    list-style: none;
    margin: 0;
    padding: 0;

    @media (min-width: 450px) {
        column-count: 2;
    }

    @media (min-width: 768px) {
        column-count: 3;
    }

    @media ${mq.medium} {
        column-count: 6;
    }
`;
