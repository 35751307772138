import styled, { css } from 'styled-components';

import { breakpoint, gapable, remCalc, vwMinMax } from '@/style';
import { Gap } from '@/types';

/**
 * Grid Wrapper
 */
export const Wrapper = styled.div<{ $gridFrom: number; $noCols: number; $gap?: Gap; $gutter?: number | [number, number] }>`
    ${({ $gridFrom, $noCols }) => css`
        ${vwMinMax({ cssProp: `maxWidth`, minValue: $gridFrom, maxValue: breakpoint.xxlarge, minViewport: $gridFrom })};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        @media (min-width: ${remCalc($gridFrom)}) {
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: wrap;

            > * {
                width: ${100 / $noCols}%;
            }
        }
    `};

    ${({ $gutter }) =>
        $gutter && typeof $gutter === `object`
            ? css`
                  > * {
                      ${vwMinMax({ cssProp: `paddingLeft`, minValue: $gutter[0] / 2, maxValue: $gutter[1] / 2 })}
                      ${vwMinMax({ cssProp: `paddingRight`, minValue: $gutter[0] / 2, maxValue: $gutter[1] / 2 })}
                  }
                  ${vwMinMax({ cssProp: `rowGap`, minValue: $gutter[0], maxValue: $gutter[1] })}
              `
            : css`
                  > * {
                      padding: 0 ${$gutter ? `${$gutter / 2}px` : `20px`};
                  }
                  row-$gap: ${$gutter ? `${$gutter}px` : `20px`};
              `}

    ${gapable()};
`;
