interface BaseHeadProps {
    title: string;
    noIndex?: boolean;
}

/**
 * Header, Meta-Tags etc. für die Website
 */
export const BaseHead = ({ title: titleProp, noIndex }: BaseHeadProps) => {
    const title = `Mantrailer Neckar-Alb e.V. für Personensuche und Rettungshunde / ${titleProp}`;
    return (
        <>
            <title>{title}</title>
            <meta charSet="UTF-8" />
            {noIndex && <meta name="robots" content="noindex" />}
            <meta lang="de" />
            <meta
                name="description"
                content="Mantrailer Neckar-Alb Verein für Personensuche und Rettungshunde. Im Vordergrund steht Spaß, Ausbildung und Auslastung für Hund und Mensch."
            />
            <link rel="canonical" href="https://mantrailer-neckar-alb.de/" />
            <meta property="og:locale" content="de_DE" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Mantrailer Neckar-Alb Verein für Personensuche und Rettungshunde" />
            <meta
                property="og:description"
                content="Mantrailer Neckar-Alb Verein für Personensuche und Rettungshunde. Im Vordergrund steht Spaß, Ausbildung und Auslastung für Hund und Mensch."
            />
            <meta property="og:url" content="https://mantrailer-neckar-alb.de/" />
            <meta property="og:site_name" content="mantrailer-neckar-alb.de" />
        </>
    );
};
