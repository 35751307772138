const BASE_ROUTE = `/mitgliederbereich`;

/**
 * Navigationsstruktur der Hauptnavigation
 */
export const membersRoutes = [
    {
        text: `Trainingstagebuch`,
        to: `${BASE_ROUTE}/trainingstagebuch`
    },
    {
        text: `Mitgliederliste`,
        to: `${BASE_ROUTE}/mitgliederliste`
    },
    {
        text: `Dokumente`,
        to: `${BASE_ROUTE}/dokumente`
    }
];
