import { createGlobalStyle } from 'styled-components';

import { OFF_CANVAS_ACTIVE_CLASSNAME } from '@/constants';

import { fontFamily, color, hoverSpeed, breakpoint } from './variables';
import { mq } from './mq';
import { remCalc } from './remCalc';
import { vwMinMax } from './vwMinMax';
import { pseudoStateClasses } from './pseudoStateClasses';

/**
 * Modern CSS Reset by Josh Comeau
 * @see https://www.joshwcomeau.com/css/custom-css-reset/
 */

export const GlobalStyle = createGlobalStyle`
    html {
        font-size: ${remCalc(16)};
        -moz-osx-font-smoothing: grayscale;
        &.${OFF_CANVAS_ACTIVE_CLASSNAME} {
            @media ${mq.smallOnly} {
              overflow: hidden;
              position: fixed;
            }
        }
    }

    *,
    *::after,
    *::before {
        box-sizing: border-box;
    }

    html,
    body {
        font-family: ${fontFamily};
        height: 100%;
        width: 100%;
    }

    * {
      margin: 0;
    }

    img, picture, video, canvas, svg {
      display: block;
      max-width: 100%;
    };

    input, button, textarea, select {
      font: inherit;
    }

    p, h1, h2, h3, h4, h5, h6 {
      overflow-wrap: break-word;
    };

    #___gatsby {
      isolation: isolate;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    #___gatsby > * {
      position: relative;
      min-height: 100vh;
      flex-direction: column;
      display: flex;
    }

    a {
      text-decoration: none;
      color: unset;
    };

    button {
      color: unset;
      padding: 0;
      text-align: left;
      cursor: pointer;
      border: none;
      background-color: transparent;
    };

    address {
      font-style: normal;
    };

    body {
      ${vwMinMax({ cssProp: `fontSize`, minValue: 16, maxValue: 20 })};
      line-height: 1.5;
      hyphens: auto;
      color: ${color.grayDark};
    }

    body > div, #___gatsby  {
      overflow-x: hidden;
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: normal;
      margin: 0 0 0.25em 0;
      line-height: 1.2em;
    }

    /** Schriftgrößen für die am meisten verwendeten Headline-Levels,
    wenn abweichend dann sind diese in den jeweiligen Komponenten definiert */
    h1 {
        margin-bottom: 0.75em;
        ${vwMinMax({ cssProp: `fontSize`, minValue: 25, maxValue: 40 })};
    }

    h2 {
        ${vwMinMax({ cssProp: `fontSize`, minValue: 20, maxValue: 30 })};
    }

    .navlink {
        color: ${color.grayDark};
        display: block;
        position: relative;
        text-decoration: none;
        transition: color ${hoverSpeed};

        ${vwMinMax({ cssProp: `fontSize`, minValue: 20, maxValue: 22, minViewport: 0, maxViewport: breakpoint.medium })};
        ${vwMinMax({ cssProp: `fontSize`, minValue: 20, maxValue: 28, minViewport: breakpoint.medium })};

        ${pseudoStateClasses(`
            color: ${color.primary};
        `)};

        &.navlink--active {
            color: ${color.primary};
            &::after {
                position: absolute;
                content: '';
                width: 100%;
                height: ${remCalc(2)};
                background-color: ${color.primary};

                #membersNav & {
                    bottom: -${remCalc(8)};
                }

            }
        }

        /* Anpassung Schriftgröße für Mitgliederbereich */
        #membersNav & {
              ${vwMinMax({ cssProp: `fontSize`, minValue: 16, maxValue: 18, minViewport: 0, maxViewport: breakpoint.medium })};
        }

        @media ${mq.smallOnly} {
            &::after {
                display: none;

                 #membersNav & {
                    display: block;
                 }
            }
        }

        @media ${mq.medium} {
            &::after {
                display: block;
            }
        }
    }
`;
