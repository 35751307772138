import { useAuth0 } from '@auth0/auth0-react';
import { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import arrowDown from '@/assets/img/navigation/arrow-down.svg';
import { LogoutButton } from '@/components/members';

import { Dropdown, Inner, UserDisplay, Wrapper } from './styled';

/**
 * Die User Anzeige im Header inklusive Dropdown
 * @returns
 */
export const UserPanel = () => {
    const { user } = useAuth0();
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const innerRef = useRef<HTMLDivElement>(null);

    const toggle = () => setIsExpanded(!isExpanded);
    const closeDropdown = () => setIsExpanded(false);
    useOnClickOutside(innerRef, closeDropdown);

    return (
        <Wrapper>
            <Inner ref={innerRef}>
                <UserDisplay
                    $isArrowRotated={isExpanded}
                    aria-expanded={isExpanded}
                    aria-controls="user-dropdown"
                    title="User Menü anzeigen"
                    onClick={toggle}
                >
                    {user?.nickname} <img src={arrowDown} />
                </UserDisplay>
                {isExpanded && (
                    <Dropdown id="user-dropdown">
                        <LogoutButton variant="link" />
                    </Dropdown>
                )}
            </Inner>
        </Wrapper>
    );
};
