import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '@/components/common';

import { StyledLogoutButton } from './styled';

interface LogoutButtonProps {
    variant?: 'regular' | 'link';
    className?: string;
}

const BUTTON_LABEL = `Ausloggen`;

const Icon = () => (
    <svg
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
        <polyline points="16 17 21 12 16 7"></polyline>
        <line x1="21" y1="12" x2="9" y2="12"></line>
    </svg>
);

/**
 * Stellt einen Logout-Button zur Verfügung
 */
export const LogoutButton = ({ variant = `regular`, className }: LogoutButtonProps) => {
    const { logout } = useAuth0();

    const onClick = () => {
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    if (variant === `link`) {
        return (
            <StyledLogoutButton className={className} onClick={onClick}>
                <Icon />
                {BUTTON_LABEL}
            </StyledLogoutButton>
        );
    }

    return (
        <Button wrapperClassName={className} onClick={onClick}>
            {BUTTON_LABEL}
        </Button>
    );
};
