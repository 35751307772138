import styled from 'styled-components';

import { color, pseudoStateClasses } from '@/style';

/**
 * Logout Button im Link Style
 */
export const StyledLogoutButton = styled.button`
    align-items: baseline;
    display: flex;
    font-size: 14px;
    gap: 0.5rem;

    svg {
        font-size: 16px;
        position: relative;
        top: 0.125rem;
    }

    ${pseudoStateClasses(`
        color: ${color.secondary};
    `)};
`;
