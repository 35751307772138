import styled, { css, keyframes } from 'styled-components';
import { rgba } from 'polished';

import { breakpoint, color, mq, position, remCalc, vwMinMax } from '@/style';

/**
 * Wrapper um die ganze Komponente
 */
export const Wrapper = styled.div`
    @media ${mq.smallOnly} {
        border-top: 1px solid ${color.grayDark};
        padding-top: 1rem;
    }

    @media ${mq.medium} {
        border-left: 1px solid ${color.grayDark};
        ${vwMinMax({ cssProp: `paddingLeft`, minValue: 30, maxValue: 50, minViewport: breakpoint.medium })};
    }
`;

/**
 * Wrapper innerhalb
 */
export const Inner = styled.div`
    position: relative;
`;

/**
 * Animation für das Dropdown
 */
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1,
  }
`;

/**
 * Zeigt den Username und triggered das Dropdown
 */
export const UserDisplay = styled.button<{ $isArrowRotated?: boolean }>`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    > img {
        width: 18px;
        transition: transform 0.25s;

        ${({ $isArrowRotated }) =>
            $isArrowRotated &&
            css`
                transform: rotate(180deg);
            `};
    }
`;

/**
 * Dropdown, welches Logout etc. anzeigt
 */
export const Dropdown = styled.div`
    ${position({ top: `2rem`, left: `0` })};
    animation: ${fadeIn} 0.25s;
    background-color: ${color.white};
    border-radius: ${remCalc(5)};
    box-shadow: ${remCalc(0, 0, 10, 2)} ${rgba(color.black, 0.15)};
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: ${remCalc(10)};
    width: 100%;
`;
