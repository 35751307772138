import styled from 'styled-components';

import { gapable, remCalc } from '@/style';
import zigzagSVG from '@/assets/img/zigzag.svg';
import { Gap } from '@/types';

/**
 * ZigZag Linie
 */
export const Zigzag = styled.div<{ $gap?: Gap }>`
    background: url(${zigzagSVG});
    background-repeat: no-repeat;
    background-position: center center;
    height: ${remCalc(12)};
    margin-left: auto;
    margin-right: auto;
    width: ${remCalc(110)};
    ${gapable(`1em`)}
`;
