import styled from 'styled-components';

import { vwMinMax, pseudoStateClasses, position, color, hoverSpeed, breakpoint } from '@/style';
import arrowLeft from '@/assets/img/navigation/arrow-left.svg';
import arrowRight from '@/assets/img/navigation/arrow-right.svg';

export const ArrowButton = styled.button<{ direction?: 'left' | 'right'; disabled?: boolean }>`
    ${vwMinMax({ cssProp: `width`, minValue: 35, maxValue: 50, minViewport: breakpoint.medium })};
    ${vwMinMax({ cssProp: `height`, minValue: 35, maxValue: 50, minViewport: breakpoint.medium })};
    background: ${color.primary} url(${({ direction = `left` }) => (direction === `left` ? arrowLeft : arrowRight)});
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    background-position: ${({ direction = `left` }) => (direction === `left` ? `43%` : `56%`)} center;
    border-radius: 100%;
    background-size: 30%;
    display: block;
    cursor: pointer;
    flex-shrink: 0;
    overflow: hidden;
    transition: background-color ${hoverSpeed};

    ${pseudoStateClasses(`background-color: ${color.secondary}`)};

    ${({ disabled }) =>
        disabled &&
        `
        cursor: not-allowed;
        pointer-events: none;
        &:before {
            ${position({ top: 0, left: 0 })};
            content: '';
            background-color: ${color.white};
            opacity: 0.45;
            display: block;
            width: 100%;
            height: 100%;
        }
    `};
`;
