import { GatsbyImage } from 'gatsby-plugin-image';

import { TeamsQueryData } from '@/types';

import { withoutEdgesAndNodes } from '../withoutEdgedAndNodes';
import { isDefined } from '../isDefined';

/**
 * Transfomiert Daten der Team-Query (allPrismicTeam) in ein renderbares Format
 */
export const transformTeamsTeaserQueryData = ({ result: { edges } }: TeamsQueryData) =>
    withoutEdgesAndNodes(edges).map(({ data: { dog, teaserImage, members }, id }) => ({
        id: id.replaceAll(`_`, `-`),
        dogRip: isDefined(dog.document.data.death_date),
        teaserImage: teaserImage ? <GatsbyImage image={teaserImage.gatsbyImageData} alt={teaserImage.alt ?? ``} /> : null,
        title: !isDefined(dog.document.data.death_date)
            ? `${members.map(({ member }) => member.document.data.firstname).join(`, `)} & ${dog.document.data.name}`
            : (dog.document.data.name as string)
    }));
